"use client";

import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
	useEffect(() => {
		captureException(error);
	}, [error]);

	return (
		<div>
			<h2>Something went wrong! (root)</h2>
			<button onClick={() => reset()}>Try again</button>
		</div>
	);
}
